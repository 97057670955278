import styles from "./styles.module.scss";
import { Cell, ColumnGroup } from "react-table";
import isEmpty from "lodash/isEmpty";
import { isNil } from "lodash";
import { DateTime } from "luxon";
import { ICertificatePreviewModel } from "models/dto/ZoomiLxp/Models/Certificates/ICertificatePreviewModel";
import { ReactComponent as ShareIcon } from "assets/icons/ic_share-2.svg";
import { useAppStore } from "store";
import LoadingIndicator from "components/partial/loading-indicator/loading-indicator";
import { observer } from "mobx-react";
import { NavLink, generatePath } from "react-router-dom";
import { Routes } from "routes";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { useState } from "react";

export const CertificateDownloadCell = observer(({ certId }: { certId: string }) => {
	const { certificatesStore } = useAppStore();
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const handleDownload = async () => {
		setIsLoading(true);
		const certUrl = await certificatesStore.getCertificatePdfUrl(certId);
		setIsLoading(false);
		window.open(certUrl, "_blank");
	};
	return (
		<div className={styles.certificate_download_cell}>
			<LoadingIndicator loading={isLoading} spinnerPosition="center">
				{!isLoading && <ShareIcon onClick={handleDownload} className={styles.share_icon} />}
			</LoadingIndicator>
		</div>
	);
});

export const CourseVersionLink = ({ courseId, versionId }: { courseId: number; versionId: number }) => {
	const { usersStore } = useAppStore();
	const isLinkActive = usersStore.checkOneOfUserPermissions([
		PermissionConstantsRead.OtherAllUsersAdditionalInformation,
	]);
	return isLinkActive ? (
		<NavLink to={generatePath(Routes.CmsCourseProfileVersion, { id: courseId, versionId })}>{versionId}</NavLink>
	) : (
		<div>{versionId}</div>
	);
};

export const Columns = (): ColumnGroup<ICertificatePreviewModel>[] => {
	return [
		{
			Header: "Purchases",
			columns: [
				{
					Header: "Course",
					accessor: "courseTitle",
					Cell({ cell: { value } }) {
						return !isEmpty(value) ? <>{value}</> : <></>;
					},
				},
				{
					Header: "Download",
					id: "0",
					accessor: (row: ICertificatePreviewModel) => {
						return row;
					},
					Cell({ cell }: { cell: Cell<ICertificatePreviewModel> }) {
						return <CertificateDownloadCell certId={cell.row.original.certId} />;
					},
				},
				{
					Header: "Certificate issue date",
					accessor: "certIssueDate",
					Cell({ cell: { value } }) {
						return !isNil(value) ? DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED) : <>-</>;
					},
				},
				{
					Header: "Course version date",
					accessor: "courseVersionDate",
					Cell({ cell: { value } }) {
						return !isNil(value) ? DateTime.fromISO(value).setLocale("en").toLocaleString(DateTime.DATE_MED) : <>-</>;
					},
				},
				{
					Header: "Course version ID",
					id: "courseVersionId",
					accessor: (row: ICertificatePreviewModel) => {
						return row;
					},
					Cell({ cell }: { cell: Cell<ICertificatePreviewModel> }) {
						return (
							<CourseVersionLink courseId={cell.row.original.courseId} versionId={cell.row.original.courseVersionId} />
						);
					},
				},
				{
					Header: "Certificate ID",
					accessor: "certId",
					Cell({ cell: { value } }) {
						return value;
					},
				},
			],
		},
	];
};
