import { AccountStore } from "./account";
import { createContext, useContext } from "react";
import { UsersStore } from "./users/users-store";
import { CompaniesStore } from "./companies/companies-store";
import { ContentStore } from "./content/content-store";
import { InfluencersStore } from "./influencers/influencers-store";
import { HistoryStore } from "./history/history-store";
import { SearchStore } from "./search/search-store";
import { PeersStore } from "./peers/peers-store";
import { CoursesStore } from "./courses/courses-store";
import { LmsStore } from "./lms/lms-store";
import { QuizStore } from "./quiz/quiz-store";
import { TaxonomyStore } from "./taxonomy/taxonomy-store";
import { BillingStore } from "./billing/billing-store";
import { GoalsStore } from "./goals/goals-store";
import { AnalyticsStore } from "./analytics/analytics-store";
import { PlayerStore } from "./player/player-store";
import { NotesStore } from "./notes/notes-store";
import { SettingsStore } from "./settings-store/settings-store";
import { ReportsStore } from "./reports/reports-store";
import { QuizResultsStore } from "./quiz-results/quiz-results-store";
import { NotificationsStore } from "./notifications/notifications-store";
import { BulkOperationsStore } from "./bulk-operations/bulk-operations-store";
import { RecommendationsStore } from "./recommendations/recommendations-store";
import { CommonStore } from "./common-store/common-store";
import { ToasterStore } from "./toaster-store/toaster-store";
import { GroupStore } from "./groups/group-store";
import { CertificatesStore } from "./certificates-store/certificates-store";
import { AISummaryStore } from "./ai-summary/ai-summary-store";

export class AppStore {
	accountStore: AccountStore;
	usersStore: UsersStore;
	companiesStore: CompaniesStore;
	contentStore: ContentStore;
	influencersStore: InfluencersStore;
	historyStore: HistoryStore;
	searchStore: SearchStore;
	peersStore: PeersStore;
	coursesStore: CoursesStore;
	lmsStore: LmsStore;
	quizStore: QuizStore;
	taxonomyStore: TaxonomyStore;
	billingStore: BillingStore;
	goalsStore: GoalsStore;
	analyticsStore: AnalyticsStore;
	playerStore: PlayerStore;
	notesStore: NotesStore;
	settingsStore: SettingsStore;
	reportsStore: ReportsStore;
	quizResultsStore: QuizResultsStore;
	notificationsStore: NotificationsStore;
	bulkOperationsStore: BulkOperationsStore;
	recommendationsStore: RecommendationsStore;
	aiSummaryStore: AISummaryStore;
	commonStore: CommonStore;
	toasterStore: ToasterStore;
	groupStore: GroupStore;
	certificatesStore: CertificatesStore;

	constructor() {
		this.accountStore = new AccountStore(this);
		this.usersStore = new UsersStore(this);
		this.companiesStore = new CompaniesStore();
		this.settingsStore = new SettingsStore(this);
		this.contentStore = new ContentStore(this);
		this.peersStore = new PeersStore(this);
		this.influencersStore = new InfluencersStore(this);
		this.historyStore = new HistoryStore();
		this.searchStore = new SearchStore();
		this.coursesStore = new CoursesStore(this);
		this.lmsStore = new LmsStore(this);
		this.quizStore = new QuizStore();
		this.taxonomyStore = new TaxonomyStore();
		this.billingStore = new BillingStore(this);
		this.analyticsStore = new AnalyticsStore();
		this.goalsStore = new GoalsStore(this);
		this.playerStore = new PlayerStore();
		this.notesStore = new NotesStore();
		this.reportsStore = new ReportsStore();
		this.quizResultsStore = new QuizResultsStore();
		this.notificationsStore = new NotificationsStore();
		this.bulkOperationsStore = new BulkOperationsStore(this);
		this.recommendationsStore = new RecommendationsStore(this);
		this.aiSummaryStore = new AISummaryStore();
		this.commonStore = new CommonStore();
		this.toasterStore = new ToasterStore();
		this.groupStore = new GroupStore(this);
		this.certificatesStore = new CertificatesStore();
	}
}

export const AppStoreContext = createContext<AppStore>(null as any);

export const useAppStore = () => {
	return useContext(AppStoreContext);
};

export const appStoreInstance = new AppStore();
