import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as PauseIcon } from "assets/icons/ic_player_pause.svg";
import { ReactComponent as PlayIcon } from "assets/icons/ic_player_play.svg";
import { ReactComponent as VolumeIcon } from "assets/icons/ic_player_volume.svg";
import { ReactComponent as VolumeMuteIcon } from "assets/icons/ic_player_volume-mute.svg";
import { ReactComponent as VolumeLowIcon } from "assets/icons/ic_player_volume-low.svg";
import { ReactComponent as FullScreenIcon } from "assets/icons/ic_player_fullscreen.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/ic_player_settings.svg";
import { ReactComponent as ZoomIcon } from "assets/icons/ic_zoom-out.svg";
import { ReactComponent as FullScreenCompressIcon } from "assets/icons/ic_player_fullscreen_compress.svg";
import { ReactComponent as DownloadIcon } from "assets/icons/ic_player_download.svg";
import { ReactComponent as LeftIcon } from "assets/icons/ic_player_left.svg";
import { ReactComponent as RightIcon } from "assets/icons/ic_player_right.svg";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import { railStyle, trackStyle } from "./slider.styles";
import screenfull from "screenfull";
import { useDebounce } from "@react-hook/debounce";
import { mobileCheck, tabletCheck } from "helpers/device.helper";
import debounce from "lodash/debounce";
import ReactPlayer from "react-player/lazy";
import isNull from "lodash/isNull";
import PlayerSpeed from "../player-speed/player-speed";
import Recommendations from "components/partial/recommendations/recommendations";
import { useAppStore } from "store";
import { ISegmentPositions } from "../player-layout/player-layout";
import PlayerZoom from "../player-zoom/player-zoom";
import Tooltip from "components/base/tooltip/tooltip";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { toBoolean } from "components/partial/cms/cms-settings/settings-converter";
import AISummaryButton, { EAISummaryType } from "components/partial/ai-summary-button/ai-summary-button";
import AiSummaryInPlayer from "components/partial/ai-summary-in-player/ai-summary-in-player";

export interface ControlParams {
	currentProgress: number;
	totalProgress: number;
	boxRef: React.RefObject<HTMLDivElement>;
	getFormattedProgress: (value: number) => string;
	playing: boolean;
	volume?: number;
	muted?: boolean;
	playerRef?: React.RefObject<ReactPlayer>;
	handleStart?: () => void;
	handlePlay?: () => void;
	handlePause?: () => void;
	handleVolumeMute?: () => void;
	handleVolumeUnmute?: (volume?: number) => void;
	handleSeekChange?: (value: number) => void;
	handleVolumeLevel?: React.FormEventHandler<HTMLInputElement> | undefined;
	handlePrev?: (settings: ControlParams) => void;
	handleNext?: (settings: ControlParams) => void;
	handleComplete?: () => void;
	isPrev?: boolean;
	isNext?: boolean;
	loading?: boolean;
	speed?: number;
	handleSpeed?: (speed: number) => void;
	isReady?: boolean;
	isDownload?: boolean;
	urlDownload?: string;
}

interface PlayerControlProps {
	params: ControlParams;
	className?: string;
	segment?: ISegmentPositions;
	zoomLimitMultiplier?: number;
	onFullscreenChange?: (isFullScreen: boolean) => void;
}

export interface IPlayerSettings {
	value: number;
	label: string;
}

const cx = classNames.bind(styles);

const DELAY_HIDING_CONTROL = 10000;

function PlayerControl({ params, className, segment, zoomLimitMultiplier, onFullscreenChange }: PlayerControlProps) {
	const {
		playing,
		muted,
		currentProgress,
		totalProgress,
		volume,
		boxRef,
		handlePlay,
		handlePause,
		handleVolumeMute,
		handleSeekChange,
		handleVolumeLevel,
		handleVolumeUnmute,
		getFormattedProgress,
		handleNext,
		handlePrev,
		isPrev,
		isNext,
		speed,
		handleSpeed,
		isDownload,
		urlDownload,
	} = params;
	const { recommendationsStore, playerStore, settingsStore } = useAppStore();
	const [isFullScreen, setFullScreen] = useDebounce<boolean>(false, 200);
	const [isControlHidden, setIsControlHidden] = useState<boolean>(false);
	const [isShowSpeedMenu, setShowSpeedMenu] = useState(false);
	const [isShowZoomMenu, setShowZoomMenu] = useState(false);
	const [isShowVolumeMenu, setShowVolumeMenu] = useState(false);
	const [currentVolume, setCurrentVolume] = useState<number>(volume ?? 0);
	const [baseSegment] = useState(segment);
	const [disableFullScreen, setDisableFullScreen] = useState(false);
	const [sliderProgressValue, setSliderProgressValue] = useState<number>(0);
	const navArrowsTooltipPostfix =
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskCourseItemsNameTooltipForArrowButtons];
	const isAlwaysShowNavArrows = toBoolean(
		settingsStore.publicSettings.Pages?.[SiteSettingsKeys.MyDeskShowAlwaysPrevNextButtons]
	);

	const onChangeFullScreen = useCallback(() => {
		if (onFullscreenChange) {
			onFullscreenChange(screenfull.isFullscreen);
		}

		if (screenfull && isFullScreen !== screenfull.isFullscreen) setFullScreen(screenfull.isFullscreen);
	}, [isFullScreen, setFullScreen, onFullscreenChange]);

	useEffect(() => {
		if (currentProgress) {
			setSliderProgressValue(Math.floor(currentProgress));
		}
	}, [currentProgress]);

	useEffect(() => {
		if (!disableFullScreen) {
			try {
				screenfull.on("change", onChangeFullScreen);
				return () => {
					screenfull.off("change", onChangeFullScreen);
				};
			} catch (error) {
				setDisableFullScreen(true);
			}
		}
	}, [disableFullScreen, onChangeFullScreen]);

	useEffect(() => {
		const controlShow = () => {
			setIsControlHidden(false);
		};
		if (isControlHidden) {
			document.addEventListener("mousemove", controlShow);
		}

		return () => {
			document.removeEventListener("mousemove", controlShow);
		};
	}, [isControlHidden]);

	useEffect(() => {
		const controlHide = debounce(() => {
			setIsControlHidden(true);
		}, DELAY_HIDING_CONTROL);

		if (!isControlHidden) {
			controlHide();
		}
		return () => {
			document.removeEventListener("mousemove", controlHide);
		};
	}, [isControlHidden]);

	const handleFullScreen = () => {
		if (screenfull.isEnabled) {
			screenfull.toggle(boxRef.current as Element);
			setFullScreen((state: boolean) => !state);

			const showNotice = recommendationsStore.isShowNotice;
			const isShowList = recommendationsStore.isShowList;
			setTimeout(() => {
				recommendationsStore.isShowNotice = showNotice;
				recommendationsStore.isShowList = isShowList;

				//TODO: temporary decision to prevent jumping on FS mode... Maybe updating library for latest version can fix this problem, need to check.
				let page = isFullScreen ? currentProgress : currentProgress + 1;
				if (currentProgress + 1 > totalProgress) {
					page = totalProgress;
				} else if (currentProgress - 1 <= 0) {
					page = 0;
				}
				boxRef.current
					?.querySelector(`[data-page-number="${page}"]`)
					?.scrollIntoView({ behavior: "auto", block: "center", inline: "center" });
			}, 500);
		}
	};

	useEffect(() => {
		if (baseSegment && currentProgress >= baseSegment.endPosition) {
			handlePause?.();
		}
	}, [currentProgress, baseSegment, handlePause]);
	const isMobile = useMemo(() => mobileCheck(), []);
	const isTablet = useMemo(() => tabletCheck, []);

	const getVolumeButton = () => {
		if (isMobile || isTablet) {
			return (
				<button
					className={cx(styles.player_control__btn, styles.player_control__btn_volume, styles.player_control__disabled)}
				>
					<VolumeMuteIcon className={cx(styles.player_control__icon, styles.player_control__icon_volume_mute)} />
				</button>
			);
		} else
			return (
				<span
					className={styles.player_control__volume_area}
					onMouseOver={() => setShowVolumeMenu(true)}
					onMouseLeave={() => setShowVolumeMenu(false)}
				>
					<button
						className={cx(styles.player_control__btn, styles.player_control__btn_volume, {
							[styles.player_control__disabled]: isNull(volume),
						})}
					>
						{!muted && Number(volume) >= 0.5 && (
							<VolumeIcon className={styles.player_control__icon} onClick={() => handleVolumeMute?.()} />
						)}
						{!muted && Number(volume) > 0 && Number(volume) < 0.5 && (
							<VolumeLowIcon
								className={cx(styles.player_control__icon, styles.player_control__icon_volume_mute)}
								onClick={() => handleVolumeMute?.()}
							/>
						)}
						{(muted || volume === 0) && (
							<VolumeMuteIcon
								className={cx(styles.player_control__icon, styles.player_control__icon_volume_mute)}
								onClick={() => handleVolumeUnmute?.(currentVolume)}
							/>
						)}
					</button>
					<div
						className={cx(styles.player_control__volume_level, {
							[styles.player_control__volume_level__show]: isShowVolumeMenu,
						})}
					>
						<input
							type="range"
							min="0"
							max="1"
							className={styles.player_control__volume_slider}
							step="0.1"
							onInput={(e) => {
								handleVolumeLevel?.(e);
								setCurrentVolume(Number((e.target as HTMLInputElement).value));
							}}
							value={volume ? Number(volume) : 0}
						/>
					</div>
				</span>
			);
	};

	const onSpeed = (speed: number) => {
		handleSpeed?.(speed);
	};

	const onZoom = (zoom: number) => {
		playerStore.zoomMultiplier = zoom;
	};

	return (
		<>
			{screenfull.isFullscreen && <Recommendations className={styles.player_control__personalisations} />}
			{screenfull.isFullscreen && <AiSummaryInPlayer />}
			<div
				className={cx(styles.player_control__btn_box, styles.player_control__btn_box__left, {
					[styles.player_control__btn_box__left_hidden]: isControlHidden && !isAlwaysShowNavArrows,
					[styles.player_control__disabled]: !isPrev,
				})}
			>
				<Tooltip
					label={`Previous ${navArrowsTooltipPostfix}`}
					className={styles.arrow_tooltip__left}
					classNameContent={styles.arrow_tooltip__content}
				>
					<button
						className={cx(styles.player_control__btn, styles.player_control__btn_skip)}
						onClick={(event) => {
							event.preventDefault();
							handlePrev?.(params);
						}}
					>
						<LeftIcon className={styles.player_control__icon_nav} />
					</button>
				</Tooltip>
			</div>
			<div
				className={cx(styles.player_control__btn_box, styles.player_control__btn_box__right, {
					[styles.player_control__btn_box__right_hidden]: isControlHidden && !isAlwaysShowNavArrows,
					[styles.player_control__disabled]: !isNext,
				})}
			>
				<Tooltip
					label={`Next ${navArrowsTooltipPostfix}`}
					className={styles.arrow_tooltip__right}
					classNameContent={styles.arrow_tooltip__content}
				>
					<button
						className={cx(styles.player_control__btn, styles.player_control__btn_skip)}
						onClick={(event) => {
							event.preventDefault();
							handleNext?.(params);
						}}
					>
						<RightIcon className={styles.player_control__icon_nav} />
					</button>
				</Tooltip>
			</div>
			<div className={cx(styles.player_control, { [styles.player_control__hidden]: isControlHidden }, className)}>
				<div className={styles.player_control__progress_wrap}>
					<Slider
						min={baseSegment?.startPosition || 0}
						max={baseSegment ? baseSegment.endPosition : Math.floor(totalProgress)}
						onChange={(value) => setSliderProgressValue(value)}
						onAfterChange={(value) => handleSeekChange?.(value)}
						railStyle={railStyle}
						trackStyle={trackStyle}
						className={styles.player_control__slider}
						value={sliderProgressValue}
						handle={() => <></>}
					/>
				</div>
				<div className={styles.player_control__progress_box}>
					<PlayerSpeed isShow={isShowSpeedMenu} speed={Number(speed)} onSpeed={onSpeed} />
					<PlayerZoom isShowZoom={isShowZoomMenu} onZoom={onZoom} zoomLimitMultiplier={zoomLimitMultiplier} />
					<div className={styles.player_control__progress_inner}>
						<button
							className={cx(styles.player_control__btn, styles.player_control__btn_play, {
								[styles.player_control__disabled]: isNull(playing),
							})}
						>
							{playing && <PauseIcon className={cx(styles.player_control__icon)} onClick={() => handlePause?.()} />}
							{!playing && (
								<PlayIcon
									className={cx(styles.player_control__icon, styles.player_control__icon_play)}
									onClick={() => handlePlay?.()}
								/>
							)}
						</button>
						{getVolumeButton()}
						<div className={styles.player_control__timecode}>
							<time
								className={cx(styles.player_control__text, styles.player_control__indent)}
								dateTime={
									baseSegment ? (currentProgress - baseSegment.startPosition).toString() : currentProgress.toString()
								}
							>
								{getFormattedProgress?.(
									baseSegment
										? currentProgress - baseSegment.startPosition
										: currentProgress > totalProgress
										? totalProgress
										: currentProgress
								)}
							</time>
							<span className={cx(styles.player_control__text, styles.player_control__indent)}>/</span>
							<time
								className={cx(styles.player_control__text, styles.player_control__duration_time)}
								dateTime={baseSegment ? baseSegment.duration.toString() : totalProgress.toString()}
							>
								{getFormattedProgress?.(baseSegment ? baseSegment.duration : totalProgress)}
							</time>
						</div>
					</div>
					<div className={styles.player_control__progress_box_right}>
						<AISummaryButton 
							type="icon" 
							summaryType={EAISummaryType.CONTENT}
						/>
						{!!zoomLimitMultiplier && (
							<ZoomIcon
								className={cx(styles.player_control__icon, styles.player_control__btn_zoom)}
								onClick={() => {
									setShowSpeedMenu(false);
									setShowZoomMenu(!isShowZoomMenu);
								}}
							/>
						)}
						<a
							className={cx(styles.player_control__btn, styles.player_control__btn_gap, {
								[styles.player_control__disabled]: !isDownload,
							})}
							href={urlDownload ?? ""}
							download
						>
							<DownloadIcon className={styles.player_control__icon} />
						</a>
						<button
							className={cx(styles.player_control__btn, styles.player_control__btn_gap, {
								[styles.player_control__disabled]: isNull(speed),
							})}
						>
							<SettingsIcon
								className={styles.player_control__icon}
								onClick={() => {
									setShowZoomMenu(false);
									setShowSpeedMenu(!isShowSpeedMenu);
								}}
							/>
						</button>
						{!disableFullScreen && !isMobile && (
							<button className={cx(styles.player_control__btn, styles.player_control__btn_fullscreen)}>
								{!isFullScreen ? (
									<FullScreenIcon className={styles.player_control__icon} onClick={handleFullScreen} />
								) : (
									<FullScreenCompressIcon
										className={cx(styles.player_control__icon, styles.player_control__icon_compress)}
										onClick={handleFullScreen}
									/>
								)}
							</button>
						)}
					</div>
				</div>
			</div>
		</>
	);
}

export default PlayerControl;
