import { IOptionsItem } from "helpers/select.helper";
import { StylesConfig } from "react-select";
import { CSSObjectWithLabel } from "react-select/dist/declarations/src/types";

export const percentageCircleStyles = {
	pathColor: "var(--primary)",
	textColor: "var(--text-primary)",
	backgroundColor: "var(--background-layout)",
	trailColor: "var(--background-profile-body)",
	rotation: 1 / 2 + 1 / 8,
};

export const engagementPercentageCircleStyles = { ...percentageCircleStyles, pathColor: "var(--secondary)" };

export const dropdownStyles: StylesConfig<IOptionsItem> = {
	control: (styles: CSSObjectWithLabel) => ({
		...styles,
		width: "100%",
		background: "transparent",
		border: "none",
		cursor: "pointer",
	}),
	indicatorSeparator: (styles: CSSObjectWithLabel) => ({
		...styles,
		display: "none",
	}),
	valueContainer: (styles: CSSObjectWithLabel) => ({
		...styles,
		paddingTop: "4px",
		paddingRight: "1px",
	}),
	placeholder: (styles: CSSObjectWithLabel) => ({
		...styles,
		fontSize: "12px",
		textTransform: "uppercase",
	}),
	option: (styles: CSSObjectWithLabel) => ({
		...styles,
		cursor: "pointer",
		paddingLeft: "16px",
		fontSize: "16px",
		padding: "8px 16px",
		color: "var(--text-input-primary)",
		width: "100%",
		overflowWrap: "break-word",
		backgroundColor: "var(--input-background)",
		borderRadius: "10px",
		position: "relative",
		opacity: 0.7,
		":hover": {
			...styles[":hover"],
			opacity: 1,
		},
	}),
	menu: (styles: CSSObjectWithLabel) => ({
		...styles,
		width: "fit-content",
		borderRadius: "4px",
		backgroundColor: "var(--input-background)",
		fontSize: "14px",
		zIndex: 1000,
	}),
	singleValue: (styles: CSSObjectWithLabel) => ({
		...styles,
		color: "var(--text-primary)",
		fontWeight: 500,
		textOverflow: "ellipsis",
		overflow: "hidden",
		whiteSpace: "nowrap",
		fontSize: "12px",
		textTransform: "uppercase",
	}),
};

export const motifsFilterDropdownStyles: StylesConfig<IOptionsItem> = {
	...dropdownStyles,
	control: (styles: CSSObjectWithLabel) => ({
		...styles,
		width: "100%",
		background: "var(--text-general)",
		border: "none",
		cursor: "pointer",
	}),
	singleValue: (styles: CSSObjectWithLabel) => ({
		...styles,
		color: "var(--text-secondary)",
    	fontWeight: "500",
    	fontSize: "12px",
    	textTransform: "uppercase",
	}),
};
