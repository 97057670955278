import styles from "./styles.module.scss";
import { Form, Formik } from "formik";
import Slider, { createSliderWithTooltip } from "rc-slider";
import { activeDotStyle, dotStyle, handleStyle, railStyle, trackStyle } from "./slider.styles";
import React from "react";
import "rc-slider/assets/index.css";
import classNames from "classnames/bind";
import Button from "components/base/button/button";
import { useAppStore } from "store";
import { IUpdateGoalModel } from "models/dto/ZoomiLxp/Models/Goals/IUpdateGoalModel";
import { RED } from "constants/colors";

interface GoalsFormProps {
	categoryId: number;
	weight?: number;
	className?: string;
	onSave?: (IUpdateGoalModel: IUpdateGoalModel) => void;
	onCancel?: () => void;
	onError?: (error: string) => void;
	isNew?: boolean;
}

const cx = classNames.bind(styles);

const GoalsForm = (props: GoalsFormProps) => {
	const { categoryId, weight = 10, onSave, onCancel, className, isNew = true, onError } = props;
	const SliderWithTooltip = createSliderWithTooltip(Slider);

	const { goalsStore } = useAppStore();

	if (categoryId === 0) return <></>;

	const category = goalsStore.getCategoryById(categoryId);
	const color = category?.color ?? RED;

	return (
		<div className={cx(styles.goals_form, className)}>
			<Formik
				initialValues={{ weight: weight ?? 10 }}
				onSubmit={() => {}}
				validateOnChange={false}
				enableReinitialize={true}
			>
				{({ values, resetForm, setFieldValue }) => (
					<Form>
						<SliderWithTooltip
							min={0}
							max={100}
							railStyle={railStyle}
							trackStyle={trackStyle(color)}
							className={styles.goals_form__slider}
							value={values.weight}
							step={10}
							dots={true}
							dotStyle={dotStyle}
							activeDotStyle={activeDotStyle(color)}
							handleStyle={handleStyle(color)}
							onChange={(value) => {
								if (value === 0) {
									setFieldValue("weight", 10);
									onError?.("There must be at least 10% of free time per category");
								} else {
									setFieldValue("weight", value);
								}
							}}
						/>
						<div className={styles.goals_form__wrap}>
							<Button
								type="secondary"
								className={styles.goals_form__cancel}
								label="Cancel"
								labelColor="secondaryText"
								onClick={() => {
									resetForm();
									onCancel?.();
								}}
							/>
							<Button
								size="common"
								className={styles.goals_form__save}
								label={isNew ? "Add" : "Save"}
								onClick={() => {
									resetForm();
									onSave?.({
										weight: values.weight,
									} as IUpdateGoalModel);
								}}
							/>
						</div>
					</Form>
				)}
			</Formik>
		</div>
	);
};

export default GoalsForm;
