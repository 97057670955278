import { useEffect } from "react";
import AISummaryNotice from "./components/ai-summary-notice/ai-summary-notice";
import { useAppStore } from "store";
import { observer } from "mobx-react";
import AISummaryText from "./components/ai-summary-text/ai-summary-text";
interface AISummaryProps {
	className?: string;
}

const AISummaryInPlayer = ({ className }: AISummaryProps) => {
	const { recommendationsStore, contentStore, toasterStore } = useAppStore();

	useEffect(() => {
		if (contentStore.sessionId) {
			recommendationsStore
				.getRecommendations(contentStore.sessionId)
				.catch((err) => toasterStore.showErrorMessage(err));
		}
	}, [contentStore.sessionId, toasterStore, recommendationsStore]);

	useEffect(() => {
		return () => {
			recommendationsStore.clear();
		};
	}, [recommendationsStore]);

	return (
		<>
			<AISummaryNotice className={className} />
			<AISummaryText className={className} />
		</>
	);
};

export default observer(AISummaryInPlayer);
