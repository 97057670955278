import { makeAutoObservable, runInAction } from "mobx";

export class AISummaryStore {
	private _isShowSegmentAlert = false;
	private _isShowText = false;
	private _initialType: "SEGMENT" | "CONTENT" | null = null;
	private _segmentSummary: string | null = null;
	private _contentSummary: string | null = null;
	public motiffsGroupId: number | null | undefined = null;
	public isAiSummaryRequested = false;

	constructor() {
		makeAutoObservable(this);
	}

	get isShowSegmentAlert(): boolean {
		return this._isShowSegmentAlert;
	}

	set isShowSegmentAlert(value: boolean) {
		this._isShowSegmentAlert = value;
	}

	get isShowText(): boolean {
		return this._isShowText;
	}

	set isShowText(value: boolean) {
		this._isShowText = value;
	}
	get initialType(): "SEGMENT" | "CONTENT" | null {
		return this._initialType;
	}

	set initialType(value: "SEGMENT" | "CONTENT" | null) {
		this._initialType = value;
	}

	get segmentSummary(): string | null {
		return this._segmentSummary;
	}

	set segmentSummary(value: string | null) {
		this._segmentSummary = value;
	}

	get contentSummary(): string | null {
		return this._contentSummary;
	}

	set contentSummary(value: string | null) {
		this._contentSummary = value;
	}

	clear() {
		runInAction(() => {
			this._isShowSegmentAlert = false;
			this._segmentSummary = null;
		});
	}
}
