import styles from "./styles.module.scss";
import classNames from "classnames";
import { observer } from "mobx-react";
import { useAppStore } from "store";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import Button from "components/base/button/button";
import { useEffect, useState } from "react";

interface AISummaryTextListProps {
	className?: string;
}

const cx = classNames.bind(styles);

const AISummaryText = ({ className }: AISummaryTextListProps) => {
	const { aiSummaryStore } = useAppStore();
	const { initialType } = aiSummaryStore;

	const [showSegmentText, setShowSegmentText] = useState(initialType === "SEGMENT");
	const [showContentText, setShowContentText] = useState(initialType === "CONTENT");
	const showTabs = !!(initialType === "SEGMENT" && aiSummaryStore.contentSummary);

	const handleClose = async () => {
		setShowContentText(false);
		setShowSegmentText(false);
		aiSummaryStore.isShowSegmentAlert = false;
		aiSummaryStore.isShowText = false;
		aiSummaryStore.initialType = null;
	};

	useEffect(() => {
		setShowContentText(initialType === "CONTENT");
		setShowSegmentText(initialType === "SEGMENT");
		// eslint-disable-next-line
	}, [initialType]);

	return (
		<div
			className={cx(
				styles.ai_summary,
				{ [styles.ai_summary__show]: aiSummaryStore.isShowText },
				className
			)}
		>
			<div className={styles.ai_summary__controls}>
				{
					showTabs && <div className={styles.ai_summary__tabs}>
						<Button 
							className={styles.ai_summary__tab} 
							labelColor="secondaryText" 
							type="text" 
							label="Don't miss it..." 
							onClick={() => {
								setShowSegmentText(true);
								setShowContentText(false);
							}}/>
						<Button 
							className={styles.ai_summary__tab} 
							labelColor="secondaryText" 
							type="text" 
							label="Content summary" 
							onClick={() => {
								setShowSegmentText(false);
								setShowContentText(true);
							}}/>
					</div>
				}
				<CloseIcon className={styles.ai_summary__close_icon} onClick={handleClose} />
			</div>
			<div className={styles.ai_summary__wrap}>
				{showSegmentText && aiSummaryStore.segmentSummary}
				{showContentText && aiSummaryStore.contentSummary}
			</div>
		</div>
	);
};

export default observer(AISummaryText);
