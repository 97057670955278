import { useEffect, useRef, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import DropdownList from "components/base/dropdown-list/dropdown-list";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { CompaniesApi } from "api/controllers/CompaniesApi";
import { useAppStore } from "store";
import isEqual from "lodash/isEqual";
import { AccountApi } from "api/controllers/AccountApi";
import cloneDeep from "lodash/cloneDeep";
import { remove as lodashRemove } from "lodash";
import { getAssignmentSelectionObject, getOptions, getQueryString, initParams } from "./data";
import { EmailAddressModelType } from "models/dto/ZoomiLxp/Utilities/Enumerations/EmailAddressModelType";
import isEmpty from "lodash/isEmpty";
import sortBy from "lodash/sortBy";
import lowerCase from "lodash/lowerCase";
import Button from "components/base/button/button";
import { ReactComponent as RemoveIcon } from "assets/icons/ic_close.svg";
import isNull from "lodash/isNull";
import find from "lodash/find";
import { validateEmail } from "helpers/validator.helper";
import { IOptionsItem } from "helpers/select.helper";
import { MAX_EMAIL_LENGTH } from "constants/constants";
import { recipientsErrors } from "constants/messages";
import { IAssignmentAccountSelectionModel } from "models/dto/ZoomiLxp/Models/BulkOperations/AssignmentBulkOperation/IAssignmentAccountSelectionModel";
import { SelectionAccountsType } from "models/dto/ZoomiLxp/Utilities/Enumerations/SelectionAccountsType";

interface IRecipientsSelector {
	isBulkOperation?: boolean;
	placeholder?: string;
	isInternalEmailsOnly?: boolean;
	hideOpenIndicator?: boolean;
	openOnClick?: boolean;
	onChangeHandler?: (item: IOptionsItem) => void;
	onRemoveItemHandler?: (item: IOptionsItem) => void;
}
const RecipientsSelector = ({
	name,
	push,
	form,
	remove,
	isBulkOperation,
	placeholder,
	isInternalEmailsOnly,
	hideOpenIndicator,
	openOnClick = true,
	onChangeHandler,
	onRemoveItemHandler,
}: any & IRecipientsSelector) => {
	const { toasterStore, accountStore, usersStore, bulkOperationsStore, groupStore } = useAppStore();
	const [params, setParams] = useState<IQueryParams>(initParams);
	const [options, setOptions] = useState<IOptionsItem[]>([]);
	const [isLoading, setIsLoading] = useState(false);
	const recipient = useRef<IOptionsItem | null>(null);
	const selectInputRef = useRef<any>();

	const clearField = () => {
		setOptions([]);
		recipient.current = null;
		selectInputRef.current.blur();
	};

	useEffect(() => {
		if (!isEqual(params, initParams)) {
			setIsLoading(true);

			const usersParams = cloneDeep(params);
			lodashRemove(usersParams.filterCriteria, (item) => item.propertyNames[0] === "name");
			const queryString = getQueryString(params);

			if (bulkOperationsStore.isUsersBulkGroup || bulkOperationsStore.isCoursesOrThreadsBulkGroup) {
				Promise.allSettled([usersStore.findGroups(queryString)])
					.then((response) => {
						let options: IOptionsItem[] = [];
						if (response[0].status === "fulfilled") {
							const optionsGroup = getOptions(
								response[0].value,
								"name",
								"Group",
								isBulkOperation ? SelectionAccountsType.Group : EmailAddressModelType.Group
							);
							if (!isEmpty(optionsGroup)) options = [...options, ...optionsGroup];
						}
						const sortOptions = sortBy(options, (option) => lowerCase(option.label));
						setOptions(sortOptions);
					})
					.catch((err: unknown) => toasterStore.showErrorMessage(err))
					.finally(() => setIsLoading(false));
			} else {
				Promise.allSettled([
					CompaniesApi.getAllCompanies(params),
					AccountApi.getUsers({...usersParams, queryString}),
					accountStore.findRoles(queryString),
					usersStore.findGroups(queryString),
				])
					.then((response) => {
						let options: IOptionsItem[] = [];

						if (response[0].status === "fulfilled") {
							const records = response[0].value.data.data.records;
							const optionsCompanies = getOptions(
								records,
								"name",
								"Company",
								isBulkOperation ? SelectionAccountsType.Company : EmailAddressModelType.Company
							);
							if (!isEmpty(optionsCompanies)) options = [...options, ...optionsCompanies];
						}
						if (response[1].status === "fulfilled") {
							const records = response[1].value.data.data.records;
							const optionsNames = getOptions(
								records,
								"displayName",
								"Name",
								isBulkOperation ? SelectionAccountsType.AccountId : EmailAddressModelType.Email,
								isBulkOperation
							);
							const optionsEmails = getOptions(
								records,
								"email",
								"Email",
								isBulkOperation ? SelectionAccountsType.AccountId : EmailAddressModelType.Email,
								isBulkOperation
							);
							if (!isEmpty(optionsNames)) options = [...options, ...optionsNames];
							if (!isEmpty(optionsEmails)) options = [...options, ...optionsEmails];
						}
						if (response[2].status === "fulfilled") {
							const optionsRoles = getOptions(
								response[2].value,
								"displayName",
								"Role",
								isBulkOperation ? SelectionAccountsType.Role : EmailAddressModelType.Role
							);
							if (!isEmpty(optionsRoles)) options = [...options, ...optionsRoles];
						}
						if (response[3].status === "fulfilled") {
							const optionsGroup = getOptions(
								response[3].value,
								"name",
								"Group",
								isBulkOperation ? SelectionAccountsType.Group : EmailAddressModelType.Group
							);
							if (!isEmpty(optionsGroup)) options = [...options, ...optionsGroup];
						}
						const sortOptions = sortBy(options, (option) => lowerCase(option.label));
						setOptions(sortOptions);
					})
					.catch((err: unknown) => toasterStore.showErrorMessage(err))
					.finally(() => setIsLoading(false));
			}
		} else {
			if (!isEmpty(options)) setOptions([]);
			if (!isNull(recipient.current)) recipient.current = null;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [params]);

	const handleChange = (value: IOptionsItem | null) => {
		if (!isNull(value)) {
			const label = value?.label.split(" | ")?.[0];
			if (onChangeHandler) {
				onChangeHandler?.({ label, value: String(value?.value).split("|")?.[0] });
			}

			const newValue = { label, value: value?.value, isCreatedNow: value.isCreatedNow };
			if (!isEmpty(find(form.values[name], newValue))) {
				toasterStore.showErrorMessage(recipientsErrors.duplicateEmails);
				return;
			}

			if (isBulkOperation) {
				const newSelectionArray: IAssignmentAccountSelectionModel[] = form.values[name].map((formValue: IOptionsItem) =>
					getAssignmentSelectionObject(formValue)
				);
				const assignmentRecipients = [...newSelectionArray, getAssignmentSelectionObject(value)];
				if (bulkOperationsStore.isUsersBulkGroup) {
					bulkOperationsStore.assignmentRecipients = assignmentRecipients.map((el) => el.value);
				} else if (bulkOperationsStore.isCoursesOrThreadsBulkGroup) {
					if ((bulkOperationsStore.assignmentRecipients as string[]).includes(label)) return;
					bulkOperationsStore.assignmentRecipients = [...(bulkOperationsStore.assignmentRecipients as string[]), label];
				} else {
					bulkOperationsStore.assignmentRecipients = assignmentRecipients;
				}
			}
			push(newValue);
		}
		clearField();
	};

	const handleKey = async (event: { key: string; preventDefault: () => void }) => {
		if (event.key === "Enter") {
			event.preventDefault();
			if (bulkOperationsStore.isCoursesOrThreadsBulkGroup) {
				const fieldValue = getQueryString(params).trim();
				if (isEmpty(fieldValue)) return;
				if (groupStore.hasAllowedPrefixes(fieldValue)) {
					handleChange({
						value: `${Date.now()} | ${SelectionAccountsType.Group}`,
						label: `${fieldValue} | Group`,
					});
					toasterStore.showSuccessMessage(`New group "${fieldValue}" added and ready for furter implementation`);
				} else {
					toasterStore.showErrorMessage(
						`Name of new group should started from "${groupStore.allowedPrefixes.join(
							", "
						)}", or choose exist group from the search list`
					);
				}
			}

			if (!isInternalEmailsOnly) {
				const email = getQueryString(params);
				const newEmail = { label: email, value: email + "|" + EmailAddressModelType.Email };

				if (isEmpty(email)) {
					toasterStore.showErrorMessage(recipientsErrors.emptyEmail);
					return;
				}

				if (!validateEmail(email)) {
					toasterStore.showErrorMessage(recipientsErrors.validateEmail);
					return;
				}

				if (!isEmpty(find(form.values[name], newEmail))) {
					toasterStore.showErrorMessage(recipientsErrors.duplicateEmails);
					return;
				}

				if (email.length > MAX_EMAIL_LENGTH) {
					toasterStore.showErrorMessage(recipientsErrors.lengthEmail);
					return;
				}

				const user = await accountStore.findUserByEmail(email);
				if (user && !user.isActive) {
					toasterStore.showErrorMessage(recipientsErrors.notActiveUser);
					return;
				}

				push(newEmail);

				setOptions([]);
				recipient.current = null;
				if (selectInputRef.current) {
					selectInputRef.current.blur();
				}
			}
		}
	};

	const getPlaceholder = (): string => {
		const patterns = ["recipient", "email"];
		let result = name;
		patterns.forEach((pattern) => {
			if (name.includes(pattern)) result = name.replace(pattern, "");
		});
		return placeholder ?? result;
	};

	const removeChipHandler = (item: IOptionsItem, index: number) => {
		if (onRemoveItemHandler) {
			const label = item?.label.split(" | ")?.[0];
			const value = String(item?.value).split("|")?.[0];
			onRemoveItemHandler({ label, value });
		}
		remove(index);
		if (isBulkOperation) {
			bulkOperationsStore.assignmentRecipients = form.values[name].filter((value: IOptionsItem) => value !== item);
		}
	};

	return (
		<div className={styles.recipients_selector}>
			<DropdownList
				placeholder={getPlaceholder()}
				options={options ?? []}
				className={classNames(styles.recipients_selector__form_field, styles.recipients_selector__wide)}
				isSearchable={true}
				value={recipient.current}
				onChange={handleChange}
				querySearchParams={{
					params,
					setParams,
					isLoading,
					propertyName: "name",
				}}
				isLoading={isLoading}
				onKeyDown={handleKey}
				selectInputRef={selectInputRef}
				openOnClick={openOnClick && !hideOpenIndicator}
				customStyles={
					hideOpenIndicator
						? {
								indicatorsContainer: (prevStyle) => ({
									...prevStyle,
									display: "none",
								}),
						  }
						: undefined
				}
			/>
			<div className={styles.recipients_selector__chips_block}>
				{form.values[name]?.map((item: IOptionsItem, index: number) => {
					return (
						<Button
							key={index}
							label={item.label}
							onClick={() => removeChipHandler(item, index)}
							size="small"
							iconPosition="right"
							icon={<RemoveIcon />}
							className={styles.recipients_selector__remove_btn}
							labelColor="secondaryText"
						/>
					);
				})}
			</div>
		</div>
	);
};

export default RecipientsSelector;
