import styles from "./styles.module.scss";
import classNames from "classnames";

type Props = {
	className?: string;
};

const DotsLoadingIndicator = ({ className }: Props) => {
	return <div className={classNames(styles.loading_dots, className)}>
		<div className={styles.loading_dots__pulse}></div>
	</div>;
};

export default DotsLoadingIndicator;
