import { useRef } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { useDetectOutsideClick } from "hooks/useDetectOutsideClick";
import Button from "components/base/button/button";
import { ReactComponent as ChevronUp } from "assets/icons/ic_chevron_up.svg";
import { ReactComponent as ChevronDown } from "assets/icons/ic_chevron_down.svg";
import isEmpty from "lodash/isEmpty";

export interface ISubmenuItem {
	label: string;
	onClick: () => void;
	isDisabled?: boolean;
}

interface ButtonSubmenuProps {
	title: string;
	items: ISubmenuItem[];
	isShow: boolean;
	className?: string;
}

const ButtonSubmenu = (props: ButtonSubmenuProps) => {
	const { title, items, isShow, className } = props;

	const dropdownRef = useRef(null);
	const { isActive, setIsActive } = useDetectOutsideClick(dropdownRef, false);
	const openSubmenu = () => setIsActive(!isActive);

	if (!isShow) return <></>;

	const handleClick = (item: ISubmenuItem) => {
		setIsActive(false);
		item.onClick();
	};

	return (
		<div className={classNames(styles.button_submenu, className)}>
			<Button
				label={title}
				labelColor="secondaryText"
				onClick={openSubmenu}
				type={"primary"}
				className={styles.button_submenu__btn}
				icon={
					isActive ? (
						<ChevronUp className={styles.button_submenu__icon} />
					) : (
						<ChevronDown className={styles.button_submenu__icon} />
					)
				}
				iconPosition={"right"}
				size="common"
			/>
			{!isEmpty(items) && (
				<div
					ref={dropdownRef}
					className={classNames(styles.button_submenu__submenu, {
						[styles.button_submenu__submenu_active]: isActive,
						[styles.button_submenu__submenu_inactive]: !isActive,
					})}
				>
					<ul className={styles.button_submenu__submenu__list}>
						{items.map((item) => {
							return (
								<li
									className={classNames(styles.button_submenu__submenu__item, {
										[styles.button_submenu__disabled]: item.isDisabled,
									})}
									onClick={() => !item.isDisabled && handleClick(item)}
									key={item.label}
								>
									{item.label}
								</li>
							);
						})}
					</ul>
				</div>
			)}
		</div>
	);
};

export default ButtonSubmenu;
