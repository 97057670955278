import styles from "./styles.module.scss";
import classNames from "classnames";
import Button from "components/base/button/button";
import { createModalContext, ModalComponent } from "components/base/modal-dialog/modal-dialog";
import { ReactNode, useState } from "react";
import { isBadRequest } from "api/utils/api";
import Logo from "components/base/logo/logo";
import ButtonClose from "../button-close/button-close";

interface ConfirmationPopupProps {
	popupText?: string;
	title?: string;
	confirmationButtonLabel?: string;
	cancelButtonLabel?: string;
	confirmationAction?: () => void;
	secondaryConfirmationAction?: () => void;
	rejectionAction?: () => void;
	hideCancelBtn?: boolean;
	hideConfirmBtn?: boolean;
	resolveMessage?: string;
	showLogo?: boolean;
	children?: ReactNode;
	swapButtons?: boolean;
	isWideButtons?: boolean;
	showCloseIcon?: boolean;
	closeCallback?: (closeFn: () => void) => void;
	classNameBody?: string;
	hideDivider?: boolean;
}

interface ConfirmationPopupResult {
	resolveMessage: string;
}

const { modalContext, useModalContext } = createModalContext<ConfirmationPopupResult>();

const ConfirmationPopup = (props: ConfirmationPopupProps) => {
	const {
		popupText = "",
		title,
		confirmationButtonLabel,
		cancelButtonLabel,
		confirmationAction,
		secondaryConfirmationAction,
		rejectionAction,
		hideCancelBtn,
		hideConfirmBtn,
		resolveMessage,
		showLogo,
		swapButtons,
		children,
		isWideButtons,
		showCloseIcon,
		closeCallback,
		classNameBody,
		hideDivider = false,
	} = props;
	const { resolve } = useModalContext();
	const [isLoading, setIsLoading] = useState<boolean>();

	const hideFooter = hideCancelBtn && hideConfirmBtn;
	const confirmHandler = async (isSecondary: boolean = false) => {
		try {
			setIsLoading(true);
			isSecondary ? secondaryConfirmationAction?.() : confirmationAction?.();
			resolve({ resolveMessage: resolveMessage ?? "Done" });
		} catch (err) {
			if (isBadRequest(err)) {
				setIsLoading(false);
				const errorMessage = err.response ? err.response?.data.data.errorMessage : "Something went wrong";
				resolve({ resolveMessage: `Oops! Problem: ${errorMessage}` });
			} else {
				throw err;
			}
		}
	};

	const handleReject = () => {
		rejectionAction?.();
		resolve();
	};

	const handleCancel = () => {
		resolve();
	};

	if (closeCallback) {
		closeCallback(() => handleCancel());
	}

	return (
		<div className={styles.confirmation_popup__container}>
			<div className={styles.confirmation_popup__header}>
				{showLogo && (
					<div className={styles.confirmation_popup__logo_box}>
						<Logo />
					</div>
				)}
				{title}

				{showCloseIcon && <ButtonClose onClick={() => handleCancel()} className={styles.dialog_button__icon_cancel} />}
			</div>
			{!hideDivider && <hr className={styles.confirmation_popup__divider} />}
			<div
				className={classNames(
					styles.confirmation_popup__body,
					{
						[styles.confirmation_popup__contentNoTitle]: !title,
					},
					classNameBody
				)}
			>
				{children ?? <div dangerouslySetInnerHTML={{ __html: popupText }} />}
			</div>
			{!hideFooter && (
				<div
					className={classNames(styles.confirmation_popup__footer, {
						[styles.confirmation_popup__reversed]: swapButtons,
					})}
				>
					{!hideConfirmBtn && (
						<Button
							disabled={isLoading}
							type={swapButtons ? "primary" : "secondary"}
							labelColor="secondaryText"
							className={classNames(styles.dialog_button, { [styles.dialog_button__wide]: isWideButtons })}
							label={confirmationButtonLabel || "Ok"}
							onClick={() => confirmHandler()}
							testId={"confirm_action"}
						/>
					)}

					{!hideCancelBtn && (
						<Button
							className={classNames(styles.dialog_button__cancel, { [styles.dialog_button__wide]: isWideButtons })}
							type={swapButtons ? "secondary" : "primary"}
							labelColor={swapButtons ? "secondaryText" : "primaryText"}
							label={cancelButtonLabel || "Cancel"}
							onClick={() => (!!secondaryConfirmationAction ? confirmHandler(true) : handleReject())}
						/>
					)}
				</div>
			)}
		</div>
	);
};

export const ConfirmationModal: ModalComponent<ConfirmationPopupProps, ConfirmationPopupResult> = {
	component: ConfirmationPopup,
	modalContext,
};
