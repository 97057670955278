import Api, { ApiBase } from "api/utils/api";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { AxiosResponse } from "axios";
import { IResponseModel } from "models/dto/ZoomiLxp/Models/Common/IResponseModel";
import { IGetRecordsResponse } from "models/dto/ZoomiLxp/Models/Common/IGetRecordsResponse";
import { IUpdateContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentModel";
import { ICreateContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateContentModel";
import { ISegmentsModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentsModel";
import { IStateModel } from "models/dto/ZoomiLxp/Models/Common/IStateModel";
import { IStateResponseModel } from "models/dto/ZoomiLxp/Models/Common/IStateResponseModel";
import { ISegmentDisplayNameModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentDisplayNameModel";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import { IRetryProcessingModel } from "models/dto/ZoomiLxp/Models/ContentModels/IRetryProcessingModel";
import { ICreateExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateExternalContentModel";
import { IExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IExternalContentModel";
import { IUpdateExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateExternalContentModel";
import { IContentProcessingLogItemModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentProcessingLogItemModel";
import { IExtractMetaModel } from "models/dto/ZoomiLxp/Models/ContentModels/IExtractMetaModel";
import { IExtractMetaRequest } from "models/dto/ZoomiLxp/Models/ContentModels/IExtractMetaRequest";
import { IBaseSummaryModel } from "models/dto/ZoomiLxp/Models/Courses/IBaseSummaryModel";

export class ContentApi {
	static getContentById(contentId: number) {
		return Api.get<IContentModel>(`/content/${contentId}`);
	}

	static addNewContent(data: ICreateContentModel) {
		return Api.post<IContentModel>("/content/addContent", data);
	}

	static updateContent(contentId: number, data: IUpdateContentModel) {
		return Api.put(`/content/${contentId}`, data);
	}

	static getAllContent(data: IQueryParams) {
		return ApiBase.post<IQueryParams, AxiosResponse<IResponseModel<IGetRecordsResponse<IContentModel>>>>(
			"/content/GetAll",
			data
		);
	}

	static getRelatedContent(contentId: number) {
		return Api.get(`/myDesk/GetRelevalContent/${contentId}`);
	}

	static getSegments(contentId: number) {
		return Api.get<ISegmentsModel>(`/content/segments/${contentId}`);
	}

	static generateSegmentNameByAI(segmentId: number) {
		return Api.put<ISegmentModel>(`/content/segments/generateainame/${segmentId}`);
	}

	static setSegmentsActive(courseItemId: number, data: IStateModel) {
		return Api.put<IStateResponseModel, IStateModel>(`/content/segments/active/${courseItemId}`, data);
	}

	static updateNameSegment(segmentId: number, data: ISegmentDisplayNameModel) {
		return Api.put<ISegmentModel, ISegmentDisplayNameModel>(`/content/segments/updateName/${segmentId}`, data);
	}

	static retryProcessing(contentId: number) {
		return Api.post("/content/RetryProcessing", { contentId } as IRetryProcessingModel);
	}

	static addExternalContent(data: ICreateExternalContentModel) {
		return Api.post<IExternalContentModel>("/content/AddExternalContent", data);
	}

	static updateExternalContent(externalContentId: number, data: IUpdateExternalContentModel) {
		return Api.put<IExternalContentModel>(`/content/ExternalContent/${externalContentId}`, data);
	}

	static getContentLogs(contentId: number, data: any) {
		return Api.postUnwrapped<IGetRecordsResponse<IContentProcessingLogItemModel>>(`/content/logs/${contentId}`, data);
	}

	static extractUrlMeta(data: IExtractMetaRequest) {
		return Api.post<IExtractMetaModel>("/content/ExtractUrlMeta", data);
	}

	static getContentAISummary(contentId: number) {
		return Api.get<IBaseSummaryModel>(`/content/ai-summary/${contentId}`);
	}
}
