import React, { useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as AISummaryPlayerIcon } from "assets/icons/ic_player_ai_summary.svg";
import SmallLoadingIndicator from "../small-loading-indicator/smal-loading-indicator";
import DotsLoadingIndicator from "../dots-loading-indicator/dots-loading-indicator";
import { useAppStore } from "store";
import { SummaryStatus } from "models/dto/ZoomiLxp/Utilities/Enumerations/SummaryStatus";
import { IBaseSummaryModel } from "models/dto/ZoomiLxp/Models/Courses/IBaseSummaryModel";
import { getCourseItemSummaryInfo } from "helpers/content.helper";

export enum EAISummaryType {
	CONTENT = "content",
	COURSE = "course",
}

interface IAISummaryProps {
	summaryType: EAISummaryType;
	status?: SummaryStatus;
	id?: number;
	isAISummaryShowed?: boolean;
	type?: "text" | "icon";
	textToGenerate?: string;
	textToUndo?: string;
	actionOnSuccess?: (response?: IBaseSummaryModel) => void;
	actionToUndo?: () => void;
	className?: string;
}

const cx = classNames.bind(styles);

const BUTTON_TYPE = "text";
const DEFAULT_TEXT = "View AI Summary...";
const DEFAULT_UNDO_TEXT = "View Description";
export const DEFAULT_AI_SUMMARY_FAILED_TEXT = `Sorry, we couldn't generate summary… I may happen because of following reasons:
	- no response from AI, please retry later
	- no text is available for summary, please make sure that content contain text
`;

const AISummaryButton = (props: IAISummaryProps) => {
	const { 
		isAISummaryShowed = false, 
		type = BUTTON_TYPE, 
		textToGenerate = DEFAULT_TEXT, 
		textToUndo = DEFAULT_UNDO_TEXT, 
		status,
		actionToUndo, 
		actionOnSuccess, 
		className, 
		summaryType,
		id,
		} = props;
	const [loading, setLoading] = useState(false);
		const { coursesStore, contentStore, aiSummaryStore } = useAppStore();

	summaryType === EAISummaryType.COURSE && status === SummaryStatus.PENDING && !loading && setLoading(true);
	summaryType === EAISummaryType.CONTENT && coursesStore.curActiveContent.content?.summary?.status === SummaryStatus.PENDING && !loading && setLoading(true);
	
	if(loading && type === "icon") return <SmallLoadingIndicator/>;
	if(loading && type === "text") return <DotsLoadingIndicator/>;

	const onClick = async () => {
		if(isAISummaryShowed) {
			actionToUndo && actionToUndo();
			return;
		}
		setLoading(true);
		if(summaryType === EAISummaryType.COURSE) {	
			if ((!status || status === SummaryStatus.FAILED)) {
				coursesStore
					.getCourseAISummary()
					.then((res) => actionOnSuccess && actionOnSuccess(res))
					.catch((err) => console.log(err))
					.finally(() => setLoading(false));
			} else {
				setLoading(false);
				actionOnSuccess && actionOnSuccess();
			}
		}
		if(summaryType === EAISummaryType.CONTENT && (coursesStore.curActiveContent.contentId || id)) {
			const contentId = id || coursesStore.curActiveContent.contentId;
			if(aiSummaryStore.isShowSegmentAlert && aiSummaryStore.initialType === "CONTENT") {
				setLoading(false);
				aiSummaryStore.isShowSegmentAlert = false;
				aiSummaryStore.isShowText = false;
				aiSummaryStore.initialType = null;
				return;
			}
			if(contentId) {
				if(actionOnSuccess) {
					contentStore
						.getContentAISummary(contentId)
						.then((res) => actionOnSuccess(res))
						.catch((err) => console.log(err))
						.finally(() => setLoading(false));
				} else {
					// This is edge case for content button in player
					// Avoiding to include any content based logic in component for player control
					aiSummaryStore.initialType = "CONTENT";
					if(!aiSummaryStore.contentSummary) {
						contentStore
							.getContentAISummary(contentId)
							.then((res) => {
								if (res.status === SummaryStatus.FAILED) {
									aiSummaryStore.contentSummary = DEFAULT_AI_SUMMARY_FAILED_TEXT;
								} else {
									aiSummaryStore.contentSummary = res.gptSummary;
								}
								aiSummaryStore.isShowSegmentAlert = true;
								aiSummaryStore.isShowText = true;
							})
							.catch((err) => console.log(err))
							.finally(() => setLoading(false));
					} else {
						aiSummaryStore.isShowSegmentAlert = true;
						aiSummaryStore.isShowText = true;
						setLoading(false);
					}
				}
			}
		}
	};

	if((summaryType === EAISummaryType.CONTENT && !id) && !getCourseItemSummaryInfo(coursesStore.curActiveContent).canGenerateSummary) {
		return<></>;
	}

	return (
		<button
			onClick={onClick}
			className={cx(styles.ai_summary_button, className, {[styles.ai_summary_button__link]: type === "text", [styles.ai_summary_button__icon]: type === "icon"})}
		>
			{type === "text" ? isAISummaryShowed ? textToUndo : textToGenerate : <></>}
			{type === "icon" ? <AISummaryPlayerIcon className={styles.ai_summary_button__icon}/> : <></>}
		</button>
	);
};

export default AISummaryButton;
