import { SyntheticEvent } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import { observer } from "mobx-react";
import { useAppStore } from "store";

interface RecommendationsAlertProps {
	className?: string;
}

const cx = classNames.bind(styles);

const RecommendationsNotice = ({ className }: RecommendationsAlertProps) => {
	const { recommendationsStore, contentStore, aiSummaryStore } = useAppStore();
	const handleOpen = () => {
		recommendationsStore.isShowNotice = false;
		recommendationsStore.isShowList = true;
	};

	const handleClose = async (e: SyntheticEvent) => {
		e.stopPropagation();
		recommendationsStore.isShowNotice = false;
		if (contentStore.sessionId) await recommendationsStore.clearRecommendationsGroup(contentStore.sessionId);
	};

	return (
		<div
			className={cx(
				styles.recommendations_alert,
				{ [styles.recommendations_alert__show]: recommendationsStore.isShowNotice && !recommendationsStore.isShowList && !aiSummaryStore.isShowText },
				className
			)}
			onClick={handleOpen}
		>
			<div className={styles.recommendations_alert__text}>Personalization</div>
			<CloseIcon className={styles.recommendations_alert__close_icon} onClick={handleClose} />
		</div>
	);
};

export default observer(RecommendationsNotice);
