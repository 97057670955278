import { SyntheticEvent } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { ReactComponent as CloseIcon } from "assets/icons/ic_close.svg";
import { observer } from "mobx-react";
import { useAppStore } from "store";

interface AISummaryNoticeProps {
	className?: string;
}

const cx = classNames.bind(styles);

const AISummaryNotice = ({ className }: AISummaryNoticeProps) => {
	const { aiSummaryStore, recommendationsStore } = useAppStore();
	const handleOpen = () => {
		aiSummaryStore.isShowSegmentAlert = false;
		aiSummaryStore.isShowText = true;
	};

	const handleClose = async (e: SyntheticEvent) => {
		e.stopPropagation();
		aiSummaryStore.isShowSegmentAlert = false;
	};

	return (
		<div
			className={cx(
				styles.ai_summary_notice,
				{ [styles.ai_summary_notice__show]: aiSummaryStore.isShowSegmentAlert && !aiSummaryStore.isShowText && !recommendationsStore.isShowList },
				className
			)}
			onClick={handleOpen}
		>
			<div className={styles.ai_summary_notice__text}>Don't miss it...</div>
			<CloseIcon className={styles.ai_summary_notice__close_icon} onClick={handleClose} />
		</div>
	);
};

export default observer(AISummaryNotice);
