import { makeAutoObservable, runInAction } from "mobx";
import { ContentApi } from "api/controllers/ContentApi";
import { IContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentModel";
import { ISaveEventContentHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventContentHistoryModel";
import { EventApi } from "api/controllers/EventApi";
import { IUpdateContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateContentModel";
import { IQueryParams } from "models/dto/ZoomiLxp/Models/Query/IQueryParams";
import { ICreateContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateContentModel";
import { IGenerateSessionRequestModel } from "models/dto/ZoomiLxp/Models/EventModels/IGenerateSessionRequestModel";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import { ICourseItemModel } from "models/dto/ZoomiLxp/Models/Courses/ICourseItemModel";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
import { ISegmentDisplayNameModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentDisplayNameModel";
import { IKeepAliveModel } from "models/dto/ZoomiLxp/Models/EventModels/IKeepAliveModel";
import { AppStore } from "../index";
import { IContentStatusModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentStatusModel";
import { ICreateExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ICreateExternalContentModel";
import { IUpdateExternalContentModel } from "models/dto/ZoomiLxp/Models/ContentModels/IUpdateExternalContentModel";
import { ISaveEventCourseItemHistoryModel } from "models/dto/ZoomiLxp/Models/EventModels/ISaveEventCourseItemHistoryModel";
import { Emitter } from "mitt";
import { AlertEventTypes } from "components/base/alert/alert";
import { EventTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/EventTypes";
import { ISetPositionPayload } from "models/dto/ZoomiLxp/Models/EventModels/Payloads/ISetPositionPayload";
import { IStartPayload } from "models/dto/ZoomiLxp/Models/EventModels/Payloads/IStartPayload";
import * as qs from "querystringify";
import { getSegmentName } from "pages/private/cms-content-page/content-profile/content-profile.helper";
import { showErrorMessage } from "helpers/error.handling.helper";
import { ILastMotiffGroup } from "models/dto/ZoomiLxp/Models/EventModels/ILastMotiffGroup";
import { IContentProcessingLogItemModel } from "models/dto/ZoomiLxp/Models/ContentModels/IContentProcessingLogItemModel";
import { getInitParams } from "helpers/filter.helper";
import { IExtractMetaRequest } from "models/dto/ZoomiLxp/Models/ContentModels/IExtractMetaRequest";

const KEEP_ALIVE_DELAY = 60000;
const DELAY_FOR_RECOMMENDATIONS = 5000;

export enum Choice {
	NoChoice = "NoChoice",
	MultiChoice = "MultiChoice",
}

export interface ISegmentFormModel extends ISegmentModel {
	inputName?: string;
}
export interface ISaveEventCourseItemEventTimeModel {
	event: ISaveEventCourseItemHistoryModel,
	eventTime: number;
}
export class ContentStore {
	private _isLoading: boolean = false;
	private _isLogsLoading: boolean = false;
	isContentLoading: boolean = false;
	relatedContent: IContentModel[] = [];
	private _sessionId: number | undefined;
	private _contentId: number = -1;
	private _segments: ISegmentFormModel[] = [];
	private _timerId: NodeJS.Timeout = {} as NodeJS.Timeout;
	private _batchIntervalId: NodeJS.Timeout | null = null;
	private _eventsBatch: ISaveEventCourseItemEventTimeModel[] = [];
	private rootStore;
	private _alertEventBus: Emitter<AlertEventTypes> | undefined;
	private _contentInfo: IContentModel = {} as IContentModel;
	private _contentItem: IContentModel = {} as IContentModel;
	private _refCourseItem: ICourseItemModel = {} as ICourseItemModel;
	private _choice: Choice = Choice.NoChoice;
	private _contentLogs: IContentProcessingLogItemModel[] = [];
	private _contentLogsFilterParams: IQueryParams = getInitParams();

	constructor(rootStore: AppStore) {
		this.rootStore = rootStore;
		makeAutoObservable(this);
	}

	async getContentAISummary(contentId: number) {
		const response = await ContentApi.getContentAISummary(contentId);
		return response.data.data;
	}

	async addNewContent(data: ICreateContentModel) {
		this.isLoading = true;
		try {
			const response = await ContentApi.addNewContent(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async extractUrlMeta(data: IExtractMetaRequest) {
		this.isLoading = true;
		try {
			const response = await ContentApi.extractUrlMeta(data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async getAllContent(query: IQueryParams) {
		this.isContentLoading = true;
		try {
			const response = await ContentApi.getAllContent(query);
			return response.data.data.records;
		} finally {
			this.isContentLoading = false;
		}
	}

	async getRelatedContent(contentID: number) {
		const response = await ContentApi.getRelatedContent(contentID);
		runInAction(() => {
			this.relatedContent = response.data.data;
		});
		return response.data.data;
	}

	sendContentEvent = async (data: ISaveEventContentHistoryModel) => {
		if (data.sessionId === 0) {
			await this.generatePlayerSessionId({
				courseItemId: Number(this.rootStore.coursesStore.curActiveContent.courseItemId ?? 0),
				courseId: Number(data.courseId),
			} as IGenerateSessionRequestModel);
			data.sessionId = this.sessionId;
		}
		await EventApi.sendContentEvent(data);
	};

	async getContentById(contentId: number) {
		this.isLoading = true;
		try {
			const response = await ContentApi.getContentById(contentId);
			runInAction(() => {
				this.contentItem = response.data.data;
			});

			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async updateContent(contentId: number, data: IUpdateContentModel) {
		this.isLoading = true;
		try {
			const response = await ContentApi.updateContent(contentId, data);
			return response.data.data;
		} finally {
			this.isLoading = false;
		}
	}

	async retryProcessing(contentId: number) {
		const response = await ContentApi.retryProcessing(contentId);
		return response.data.data as IContentStatusModel;
	}

	setRetryStatus(contentStatus: IContentStatusModel) {
		runInAction(() => {
			this.contentInfo.status = contentStatus;
		});
	}

	set isLoading(isLoading: boolean) {
		this._isLoading = isLoading;
	}

	get isLoading() {
		return this._isLoading;
	}

	async generatePlayerSessionId(data: IGenerateSessionRequestModel) {
		try {
			if (!data.courseId && !data.courseItemId) throw new Error(`Unable to create a session, ${data}`);
			const searchObj = qs.parse(window.location.search) as any;
			if (searchObj && searchObj.ref) {
				data.transitionSource = searchObj.ref;
				data.sourceCourseId = searchObj.refCourseId ? searchObj.refCourseId : undefined;
				data.sourceCourseItemId = searchObj.refCourseItemId ? searchObj.refCourseItemId : undefined;
			}

			const result = await EventApi.generatePlayerSessionId(data);
			this.sessionId = Number(result.data.data.sessionId);

			window.sessionStorage.setItem(
				"contentStore",
				JSON.stringify({
					accountId: Number(this.rootStore.usersStore.currentUserInfo?.id ?? 0),
					contentId: Number(this.rootStore.coursesStore.curActiveContent.contentId ?? 0),
					courseId: data.courseId,
					sessionId: result.data.data.sessionId,
					segmentId: null,
				})
			);

			await this.startKeepAlive();
			return this.sessionId;
		} catch (err) {
			showErrorMessage(this.rootStore.commonStore.alertEventBus, err);
		}
	}

	get contentId(): number {
		return this._contentId;
	}

	get segments(): ISegmentFormModel[] {
		return this._segments;
	}

	private async segmentsExtractor(contentId: number) {
		runInAction(() => {
			this.isLoading = true;
		});
		try {
			this.clearSegments();
			const response = await ContentApi.getSegments(contentId);
			runInAction(() => {
				this._contentId = contentId;
				this._segments = this.normalizeName(response.data.data?.segments ?? []);
			});
			return this.normalizeName(response.data.data?.segments ?? []);
		} finally {
			runInAction(() => {
				this.isLoading = false;
			});
		}
	}

	async getSegmentsByCourseItem(courseItem: ICourseItemModel) {
		if (courseItem.contentItemType === ContentItemTypes.Content) {
			if (
				courseItem.content?.contentType === ContentTypes.Video ||
				courseItem.content?.contentType === ContentTypes.Podcast ||
				courseItem.content?.contentType === ContentTypes.Text
			) {
				this.segmentsExtractor(courseItem?.contentId ?? 0);
			}
		}
	}

	async getSegmentsByContentId(contentId: number) {
		this.segmentsExtractor(contentId);
	}

	async generateSegmentNameByAI(segmentId: number) {
		const response = await ContentApi.generateSegmentNameByAI(segmentId);
		return response.data.data;
	}

	private normalizeName(segments: ISegmentFormModel[], isAiGenerated?: boolean) {
		let result: ISegmentFormModel[] = [];

		if (!isEmpty(segments)) {
			result = segments.map((segment: ISegmentFormModel) => {
				return {
					...segment,
					inputName: isAiGenerated ? segment.aiName : getSegmentName(segment),
					// eslint-disable-next-line no-control-regex
					name: capitalize(segment.name).replace(/[^\x00-\x7F]/g, ""),
				};
			});
		}
		return result;
	}

	clearSegments() {
		runInAction(() => {
			this._contentId = -1;
			this._segments = [];
		});
	}
	async updateNameSegment(data: ISegmentDisplayNameModel) {
		const response = await ContentApi.updateNameSegment(data.segmentId, data);
		return response.data.data;
	}

	async keepAlive() {
		if (!this.rootStore.coursesStore.isCurrentCourseActive) return;
		if (!(this.rootStore.coursesStore.isPaidCourse || this.rootStore.coursesStore.isAssignedCourse)) return;

		if (this._sessionId) {
			await EventApi.keepAlive({
				sessionId: this._sessionId,
			} as IKeepAliveModel);
		}
	}

	startKeepAlive = async () => {
		this.stopKeepAlive();
		await this.keepAlive();
		this._timerId = setInterval(async () => {
			await this.keepAlive();
		}, KEEP_ALIVE_DELAY);
	};

	stopKeepAlive = () => {
		clearInterval(this._timerId);
	};

	sendBatchInterval = async () => {
		const currentTimestamp = Date.now();
		await this.sendCourseItemEventsBatch(
			this.eventsBatch.map(item => { 
				return {...item.event, millisecondsAgo: currentTimestamp - item.eventTime};
			})
		);
	}

	startBatchInterval = async (sendInterval: number) => {
		this.stopBatchInterval();
		if (!this._batchIntervalId) {
			this._batchIntervalId = setInterval(async () => {
				if (this.eventsBatch.length) {
					await this.sendBatchInterval();
					this.eventsBatch = [];
				}
			}, sendInterval);
		}
	};

	stopBatchInterval = async () => {
		if (this.eventsBatch.length) {
			await this.sendBatchInterval();
		}
		if (this._batchIntervalId) {
			clearInterval(this._batchIntervalId);
			this._batchIntervalId = null;
			this.eventsBatch = [];
		}
	};

	async addExternalContent(data: ICreateExternalContentModel) {
		const response = await ContentApi.addExternalContent(data);
		return response.data.data;
	}

	async updateExternalContent(externalContentId: number, data: IUpdateExternalContentModel) {
		const response = await ContentApi.updateExternalContent(externalContentId, data);
		return response.data.data;
	}

	get sessionId(): number | undefined {
		return this._sessionId;
	}

	set sessionId(value: number | undefined) {
		this._sessionId = value;
	}

	public sendEventCourseItemHistory = async (data: ISaveEventCourseItemHistoryModel) => {
		const resp = await EventApi.saveEventCourseItemHistory(data);
		this.initRecommendation(resp.data.data);
	};

	public sendCourseItemEventsBatch = async (data: ISaveEventCourseItemHistoryModel[]) => {
		const resp = await EventApi.saveEventCourseItemEvents(data);
		this.initRecommendation(resp.data.data);
	};

	initRecommendation(data: ILastMotiffGroup) {
		const groupId = data.motiffsGroupId;
		this.rootStore.recommendationsStore.setMotifGroup(groupId);
		this.rootStore.recommendationsStore.getRecGroupsWithDelay(this.sessionId, DELAY_FOR_RECOMMENDATIONS);
	}

	public async sendEvent(eventType: EventTypes, payload: ISetPositionPayload | IStartPayload) {
		const data: ISaveEventCourseItemHistoryModel = {
			sessionId: Number(this.sessionId),
			eventType: eventType,
			payload: JSON.stringify(payload),
		};
		this.sendEventCourseItemHistory(data);
	}

	async getContentLogs(contentId: number) {
		this.isLogsLoading = true;
		try {
			this.contentLogsFilterParams = this.rootStore.searchStore.params;
			const response = await ContentApi.getContentLogs(contentId, this.contentLogsFilterParams);
			runInAction(() => {
				this.contentLogs = response.data.data.records;
			});
			return response.data.data;
		} finally {
			this.isLogsLoading = false;
		}
	}

	get contentLogs(): IContentProcessingLogItemModel[] {
		return this._contentLogs;
	}

	set contentLogs(logsData: IContentProcessingLogItemModel[]) {
		this._contentLogs = logsData;
	}

	get contentLogsFilterParams(): IQueryParams {
		return this._contentLogsFilterParams;
	}

	set contentLogsFilterParams(params: IQueryParams) {
		this._contentLogsFilterParams = params;
	}

	get isLogsLoading(): boolean {
		return this._isLogsLoading;
	}

	set isLogsLoading(isLoading: boolean) {
		this._isLogsLoading = isLoading;
	}

	get alertEventBus(): Emitter<AlertEventTypes> | undefined {
		return this._alertEventBus;
	}

	set alertEventBus(value: Emitter<AlertEventTypes> | undefined) {
		this._alertEventBus = value;
	}

	get contentInfo(): IContentModel {
		return this._contentInfo;
	}

	set contentInfo(value: IContentModel) {
		this._contentInfo = value;
	}

	get contentItem(): IContentModel {
		return this._contentItem;
	}

	set contentItem(value: IContentModel) {
		this._contentItem = value;
	}

	get refCourseItem(): ICourseItemModel {
		return this._refCourseItem;
	}

	set refCourseItem(value: ICourseItemModel) {
		this._refCourseItem = value;
	}

	get choice(): Choice {
		return this._choice;
	}

	set choice(value: Choice) {
		this._choice = value;
	}

	get eventsBatch(): ISaveEventCourseItemEventTimeModel[] {
		return this._eventsBatch;
	}

	set eventsBatch(batch: ISaveEventCourseItemEventTimeModel[]) {
		this._eventsBatch = batch;
	}

	clearContent() {
		this.clearSegments();
		runInAction(() => {
			this._contentInfo = {} as IContentModel;
			this._contentItem = {} as IContentModel;
			this._refCourseItem = {} as ICourseItemModel;
			this._choice = Choice.NoChoice;
			this._contentLogsFilterParams = getInitParams();
		});
	}
}
