import { useEffect, useMemo, useState } from "react";
import styles from "./styles.module.scss";
import { ContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentTypes";
import PlayerVideoBox from "../player-video-box/player-video-box";
import PlayerPdfBox from "../player-pdf-box/player-pdf-box";
import PlayerHtmlBox from "../player-html-box/player-html-box";
import PlayerScormBox from "../player-scorm-box/player-scorm-box";
import canAutoPlay from "can-autoplay";
import { isSafariBrowser, mobileCheck, tabletCheck } from "helpers/device.helper";
import { useAppStore } from "store";
import isEmpty from "lodash/isEmpty";
import { ContentItemTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ContentItemTypes";
import { observer } from "mobx-react";
import PlayerQuizBox from "../player-quiz-box/player-quiz-box";
import PlayerThreadBox from "../player-thread-box/player-thread-box";
import PlayerStreamBox from "../player-stream-box/player-stream-box";
import PlayerExternalLinkBox from "../player-external-link-box/player-external-link-box";
import Recommendations from "components/partial/recommendations/recommendations";
import { PermissionConstantsAllowForTarget } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsAllowForTarget";
import { PermissionConstantsRead } from "models/dto/ZoomiLxp/Utilities/Constants/PermissionConstantsRead";
import { ExternalContentTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalContentTypes";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { DELAY_TIME_LONGER, QueryParamsConstansts } from "constants/constants";
import { ISegmentModel } from "models/dto/ZoomiLxp/Models/ContentModels/ISegmentModel";
import PlayerFinalScreen from "../player-final-screen/player-final-screen";
import { useSendEventsBatch } from "hooks/useSendEventsBatch";
import AiSummaryInPlayer from "components/partial/ai-summary-in-player/ai-summary-in-player";

export type ISegmentPositions = Pick<ISegmentModel, "startPosition" | "endPosition" | "duration">;

const PlayerLayout = () => {
	const [autoPlay, setAutoPlay] = useState(false);

	const disableAutoPlay = useMemo(() => isSafariBrowser() && (mobileCheck() || tabletCheck), []);
	const { coursesStore, usersStore, contentStore, toasterStore } = useAppStore();

	const isCourseFinished: boolean = coursesStore.currentCourse.onceCompleted;
	const isCourseUpdated: boolean = coursesStore.currentCourse.knowledgeOutdated;
	const location = useLocation();
	const history = useHistory();
	const match = useRouteMatch<{ courseItemId: string }>();
	const routeCourseItemId = Number(match.params.courseItemId);
	const isInitialLastSlide = !routeCourseItemId && isCourseFinished && !isCourseUpdated;
	const [isShowFinalScreen, setShowFinalScreen] = useState(false);
	const isShowRecommendations =
		coursesStore.curActiveContent.contentItemType !== ContentItemTypes.Assessment &&
		coursesStore.curActiveContent.contentItemType !== ContentItemTypes.ExternalContent;
	const { eventsBatchHandler } = useSendEventsBatch(isInitialLastSlide);

	useEffect(() => {
		if (coursesStore.isCourseEnded || isInitialLastSlide) {
			setShowFinalScreen(true);
		}
		return () => setShowFinalScreen(false);
	}, [
		coursesStore.curPositionContent,
		coursesStore.countContents,
		isInitialLastSlide,
		coursesStore.isCourseEnded,
		history,
	]);

	useEffect(() => {
		if (coursesStore.currentCourse.id && !contentStore.sessionId) {
			contentStore
				.generatePlayerSessionId({
					courseItemId: coursesStore.curActiveContent?.courseItemId ?? 0,
					courseId: coursesStore.currentCourse.id,
				})
				.catch((err) => toasterStore.showErrorMessage(err));
		}

		return () => {
			contentStore.sessionId = undefined;
		};
	}, [
		contentStore,
		toasterStore,
		coursesStore.currentCourse.id,
		coursesStore.curActiveContent?.courseItemId,
	]);

	useEffect(() => {
		if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.Content) {
			const content = coursesStore.curActiveContent.content;
			if (content?.contentType === ContentTypes.Video) {
				canAutoPlay.video().then((result) => {
					setAutoPlay(disableAutoPlay ? false : result?.result ?? false);
				});
			} else if (content?.contentType === ContentTypes.Podcast) {
				canAutoPlay.audio().then((result) => {
					setAutoPlay(disableAutoPlay ? false : result?.result ?? false);
				});
			}
		}
	}, [disableAutoPlay, coursesStore.curActiveContent]);

	const getPlayer = () => {
		let textError = "Something went wrong";

		if (usersStore.checkOneOfUserPermissions([PermissionConstantsAllowForTarget.Assignments])) {
			textError = coursesStore.isErrorCourse
				? coursesStore.errorText
				: "You do not have access to this course. You may ask to assign it below.";
		} else if (!usersStore.checkOneOfUserPermissions([PermissionConstantsRead.AllPermissions])) {
			textError = coursesStore.isErrorCourse
				? coursesStore.errorText
				: "You do not have access to this course. You may purchase it below.";
		}

		if (!(coursesStore.isPaidCourse || coursesStore.isAssignedCourse))
			return <div className={styles.player_layout__nodata}>{textError}</div>;

		if (coursesStore.isErrorCourse) return <div className={styles.player_layout__nodata}>{coursesStore.errorText}</div>;
		if (isShowFinalScreen)
			return (
				<div className={styles.player_layout__nodata}>
					<PlayerFinalScreen onBackButtonClick={() => setShowFinalScreen(false)} />
				</div>
			);
		if (!coursesStore.isCurrentCourseActive && !isEmpty(coursesStore.curActiveContent))
			return <div className={styles.player_layout__nodata}>Sorry, this course is not active</div>;

		if (isEmpty(coursesStore.curActiveContent))
			return <div className={styles.player_layout__nodata}>Sorry, this course is empty</div>;
		if (!coursesStore.currentChapterUnlocked)
			return (
				<div className={styles.player_layout__nodata}>
					Sorry, you don't have access to content. Please unlock access by completing quiz or watching more content from
					previous chapters.
				</div>
			);

		if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.Content) {
			const content = coursesStore.curActiveContent.content;
			const locationSearch = new URLSearchParams(location.search);
			const startPosition = locationSearch.get(QueryParamsConstansts.startPosition);

			if (startPosition) {
				const endPosition = locationSearch.get(QueryParamsConstansts.endPosition);

				setTimeout(() => {
					if (startPosition) {
						locationSearch.delete(QueryParamsConstansts.startPosition);
					}
					if (endPosition) {
						locationSearch.delete(QueryParamsConstansts.endPosition);
					}
					history.replace({
						search: locationSearch.toString(),
					});
				}, DELAY_TIME_LONGER);

				const segment: ISegmentPositions = {
					startPosition: Number(startPosition),
					endPosition: Number(endPosition),
					duration: Number(endPosition) - Number(startPosition),
				};
				if (content?.contentType === ContentTypes.Video || content?.contentType === ContentTypes.Podcast) {
					return (
						<PlayerVideoBox
							content={content}
							autoPlay={autoPlay}
							segment={segment}
							onEventHandle={eventsBatchHandler}
						/>
					);
				} else if (content?.contentType === ContentTypes.Text) {
					return <PlayerPdfBox content={content} segment={segment} onEventHandle={eventsBatchHandler} />;
				}
			} else if (content?.contentType === ContentTypes.Video || content?.contentType === ContentTypes.Podcast) {
				return <PlayerVideoBox content={content} autoPlay={autoPlay} onEventHandle={eventsBatchHandler} />;
			} else if (content?.contentType === ContentTypes.Text) {
				return <PlayerPdfBox content={content} onEventHandle={eventsBatchHandler} />;
			} else if (
				content?.contentType === ContentTypes.Html ||
				content?.contentType === ContentTypes.Mhtml ||
				content?.contentType === ContentTypes.Zip
			) {
				return <PlayerHtmlBox content={content} onEventHandle={eventsBatchHandler} />;
			} else if (content?.contentType === ContentTypes.Scorm) {
				return <PlayerScormBox content={content} />;
			}
		} else if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.Assessment) {
			return <PlayerQuizBox onEventHandle={eventsBatchHandler} />;
		} else if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.ContentSegment) {
			const content = coursesStore.curActiveContent.content;
			const segment = coursesStore.curActiveContent.contentSegment;
			if (content?.contentType === ContentTypes.Video || content?.contentType === ContentTypes.Podcast) {
				return (
					<PlayerVideoBox content={content} autoPlay={autoPlay} segment={segment} onEventHandle={eventsBatchHandler} />
				);
			} else if (content?.contentType === ContentTypes.Text) {
				return <PlayerPdfBox content={content} segment={segment} onEventHandle={eventsBatchHandler} />;
			}
		} else if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.Thread) {
			const thread = coursesStore.curActiveContent.thread;
			return <PlayerThreadBox thread={thread} onEventHandle={eventsBatchHandler} />;
		} else if (coursesStore.curActiveContent.contentItemType === ContentItemTypes.ExternalContent) {
			const externalContent = coursesStore.curActiveContent.externalContent;
			if (externalContent?.type === ExternalContentTypes.StreamMeeting) {
				return <PlayerStreamBox stream={externalContent} onEventHandle={eventsBatchHandler} />;
			} else if (externalContent?.type === ExternalContentTypes.ExternalLink) {
				return <PlayerExternalLinkBox externalContent={externalContent} onEventHandle={eventsBatchHandler} />;
			}
		}
	};

	return (
		<div className={styles.player_layout}>
			{isShowRecommendations && <Recommendations />}
			{<AiSummaryInPlayer />}
			{getPlayer()}
		</div>
	);
};

export default observer(PlayerLayout);
