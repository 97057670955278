import { ExternalIdentityProviderTypes } from "models/dto/ZoomiLxp/Utilities/Enumerations/ExternalIdentityProviderTypes";
import { useAppStore } from "store";
import { useCallback } from "react";
import { useSocialSignIn } from "./useSocialSignIn";
import { Routes } from "routes";
import { SiteSettingsKeys } from "models/dto/ZoomiLxp/Utilities/Constants/SiteSettingsKeys";
import { useLocation, useHistory, generatePath } from "react-router-dom";
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_KEYCLOAK_TOKEN_KEY;

export function useKeycloakProvider() {
	const { settingsStore, accountStore, toasterStore } = useAppStore();
	const { externalSignIn } = useSocialSignIn();
	const history = useHistory();
	const location = useLocation();
	const cliendId = settingsStore.publicSettings?.Auth[SiteSettingsKeys.DigitalUniverseClientId];
	const baseUrl = settingsStore.publicSettings?.Auth[SiteSettingsKeys.DigitalUniverseBaseUrl];
	const referrer = document.referrer;
	const hostname = window.location.hostname;
	const origin = window.location.origin;

	const ENV =
		hostname.includes("localhost") || hostname.includes("dev")
    	? "dev"
    	: hostname.includes("qa")
    	? "qa"
    	: "prod";

	const initiKeycloak = useCallback(async () => {
		if(referrer && referrer.includes("digitalu") && cliendId && baseUrl) {
			if(accountStore.isAuthenticated) {
				accountStore.signout();
			}
			window.location.href = `https://kc26${ENV !== "prod" ? `.${ENV}.` : "."}lxpcloud.com?client_id=${cliendId}&redirect_uri=${baseUrl}&redirect_origin=${origin}`;
		}
	// eslint-disable-next-line
	}, [referrer, settingsStore, cliendId, baseUrl]);

	const initiKeycloakWithoutReferrer = useCallback(async () => {
		if(cliendId && baseUrl) {
			if(accountStore.isAuthenticated) {
				accountStore.signout();
			}
			window.location.href = `https://kc26${ENV !== "prod" ? `.${ENV}.` : "."}lxpcloud.com?client_id=${cliendId}&redirect_uri=${baseUrl}&redirect_origin=${origin}`;
		}
	// eslint-disable-next-line
	}, [settingsStore, cliendId, baseUrl]);

	const checkReferrer = useCallback(() => {
		if(referrer && referrer.includes("digitalu") && location.pathname !== Routes.DigitalUniversity && !sessionStorage.getItem("redirectPath")) {
			if(accountStore.isAuthenticated) {
				accountStore.signout();
			}
			sessionStorage.setItem("redirectPath", location.pathname);
		}
	}, [referrer, location, accountStore]);

	const handleKeycloakToken = useCallback(async () => {
		const searchParams = new URLSearchParams(location.search);
		const urlToken = searchParams.get("token");
		if(urlToken && SECRET_KEY) {
			const bytes = CryptoJS.AES.decrypt(decodeURIComponent(urlToken), SECRET_KEY);
			const token = bytes.toString(CryptoJS.enc.Utf8);
			if(token) {
					externalSignIn({ externalProvider: ExternalIdentityProviderTypes.DigitalUniverse, token, redirectUri: sessionStorage.getItem("redirectPath") || "/" });
			} else {
				toasterStore.showErrorMessage("Login error");
				history.push(generatePath(Routes.Home));
			}
		}
	// eslint-disable-next-line
	}, [cliendId, baseUrl]);

	return { checkReferrer, initiKeycloak, initiKeycloakWithoutReferrer, handleKeycloakToken };
}
