import React, { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import isEmpty from "lodash/isEmpty";
import Button from "components/base/button/button";
import truncate from "lodash/truncate";
import { useShowLinksInText } from "hooks/useShowLinksInText";

interface IDescriptionProps {
	description: string;
	maxLength?: number;
	className?: string;
	isShowMoreDefault?: boolean;
	onClose?: () => void;
}

interface IDeskState {
	isActive: boolean;
	description: string;
	isShowMore: boolean;
}

const cx = classNames.bind(styles);

const MAX_LENGTH = 400;

const Description = (props: IDescriptionProps) => {
	const { description, maxLength = MAX_LENGTH, className, isShowMoreDefault = false, onClose } = props;

	const initDeskState: IDeskState = {
		isActive: false,
		description: "",
		isShowMore: isShowMoreDefault,
	};

	const [desc, setDesc] = useState<IDeskState>(initDeskState);
	const { formattedText } = useShowLinksInText(desc.description);

	useEffect(() => {
		if (!isEmpty(description)) {
			const isActive = description.length > maxLength;
			const desc =
				isActive && !isShowMoreDefault ? truncate(description, { length: maxLength, separator: " " }) : description;
			setDesc((prev) => ({ ...prev, isActive, description: desc }));
		}
	}, [description, maxLength, isShowMoreDefault]);

	useEffect(() => {
		if(!desc.isShowMore && isShowMoreDefault) {
			setDesc((prev) => ({ ...prev, isShowMore: isShowMoreDefault}));
		}
	// eslint-disable-next-line
	}, [isShowMoreDefault, description]);

	if (isEmpty(description)) return <></>;

	const onClick = () => {
		if (desc.isShowMore && onClose) {
			onClose();
		}
		setDesc((prev) => ({
			...prev,
			description: prev.isShowMore ? truncate(description, { length: maxLength, separator: " " }) : description ?? "",
			isShowMore: !prev.isShowMore,
		}));
	};

	return (
		<div className={cx(styles.description, className)}>
			<div className={styles.description__wrap}>
				<div className={styles.description__full}>{formattedText}</div>
				<Button
					label={desc.isShowMore ? "Show less" : "Show more"}
					onClick={onClick}
					type="text"
					labelColor="outlinePrimary"
					className={cx(styles.description__show_more, { [styles.description__hide]: !desc.isActive })}
				/>
			</div>
		</div>
	);
};

export default Description;
